<template>
  <div class="card">
    <div class="card-body">
      <TwoFactorAuth />
    </div>
  </div>
</template>

<script>
import TwoFactorAuth from '@/components/Account/TwoFactorAuthentication/Tfa.vue'

export default {
  components: { TwoFactorAuth }
}
</script>
